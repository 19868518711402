html, body {
    height: 100%;
    width: 100%;
    font-family: 'Roboto';
    background-color: #f0f0f0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}
.sweet-loading {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}