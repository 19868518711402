.container {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}
.form-container .MuiTextField-root {
    margin-bottom: 15px;
}

.form-container button {
    margin-top: 30px;
    min-width: 50%;
}

@media(max-width: 700px) {
    .form-container {
        width: 90%;
    }

    .form-container button {
        margin-top: 30px;
        width: 100%;
    }
}