.side-menu {
    width: auto;
    height: 100%;
    min-width: 50vw;
    background-color: midnightblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.side-menu-link {
    color: #c0c0c0;
    text-decoration: none;
    padding-bottom: 10px;
}