.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.content {
    background-color: white;
    height: auto;
    width: auto;
    padding-top: 8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
}
.content-header {
    background-color: #d3d3d3;
    position: relative;
    top: 0;
}
.footer {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 2rem;
}
.status-header {
    display: flex;
    justify-content: space-around;
}
.status-button {
    display: flex;
    justify-content: center;
    margin: 0.5rem;
}
.step-container {
    padding: 1rem;
    width: 70vw;
    max-width: 20rem;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.step-buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
}
.step-button {
    width: 6rem;
}
.links {
    color: white;
    text-decoration: none;
}