.schedule {
    display: flex;
    flex: 1;
    padding: 20px;
    flex-direction: column;
}
.schedule-content {
    margin-top: 20px;
    padding: 20px;
}
.schedule-margin {
    margin-bottom: 10px;
}